.guest-reviews-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    padding: 20px 0px;
}

.guest-reviews-section h1 {
    text-transform: uppercase;
    font-size: 30px;
}

.guest-reviews-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.guest-reviews-card {
    width: 280px;
    height: 380px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.youtube-placeholder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.play-button-overlay {
    width: 80px;
    height: 80px;
    font-size: 48px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
}