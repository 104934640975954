.introduction-section {
    height: fit-content;
    display: flex;
    align-items: start;
    padding: 20px 0px;

}

.introduction-section-left {
    width: 50%;
    height: fit-content;
    padding: 20px;


}

.introduction-section-left img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.introduction-section-right {
    width: 50%;
    height: fit-content;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;

}

.introduction-section-right h1 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 30px;
    font-family: 'montserrat', sans-serif;
}

.introduction-section-right h1 h1 {
    font-weight: 800;
    color: #b81104;
}

.after {
    position: relative;
}

.introduction-section-right h1::after {
    content: '';
    width: 20%;
    height: 3px;
    background-color: #b81104;
    position: absolute;
    bottom: -10px;
    left: 0;
    border-radius: 10px;
}

.introduction-section-right p {
    line-height: 25px;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 768px) {
    .introduction-section {
        flex-direction: column;
    }

    .introduction-section-left {
        width: 100%;
        padding: 10px;
    }

    .introduction-section-right {
        width: 100%;
        padding: 10px;
    }

    .introduction-section-right h1 {
        font-size: 20px;
    }
}