.accommodation-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    padding: 20px 0px;
}

.accommodation-section h1 {
    text-transform: uppercase;
    font-size: 30px;
}

.accommodation-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.accommodation-card {
    width: 280px;
    height: 380px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.accommodation-card img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    object-position: center center;
    transition: all 0.2s ease-in-out;
}

.accommodation-card img:hover {
    transform: scale(1.2);
}

.accommodation-card-content {
    height: 50px;
    width: 280px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    padding: 0px 10px;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accommodation-card-content h4 {
    text-transform: uppercase;
    color: #b81104;
    background-color: #fff;
    padding: 0px 10px;
    border-radius: 5px;
    font: 5px;
}

.accommodation-card-content button {
    background-color: #b81104;
    color: #fff;
    padding: 3px 10px;
    font: 16px;
    text-transform: uppercase;
    font-weight: 700;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}