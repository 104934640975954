.what-we-offer-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    padding: 20px 0px;
}

.deer-background {
    width: 400px;
    height: 400px;
    position: absolute;
    top: -80px;
    right: 100px;
    background-image: url(" ../assets/deer-bg.webp");
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: top right;
    opacity: 80%;
}

.what-we-offer-section h1 {
    text-transform: uppercase;
    font-size: 30px;
}

.what-we-offer-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.what-we-offer-card {
    width: 280px;
    height: 380px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

}

.what-we-offer-card img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    object-position: center center;
    transition: all 0.2s ease-in-out;
}

.what-we-offer-card img:hover {
    transform: scale(1.2);
}

.what-we-offer-card-content {
    height: 50px;
    width: 280px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    padding-left: 20px;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.what-we-offer-card-content h3 {
    width: fit-content;
    padding: 0px 10px;
    text-transform: uppercase;
    background-color: #B81104;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .deer-background {
        background-size: 250px;
        top: -40px;
        right: 50px;
        opacity: 30%;
    }
}