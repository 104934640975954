.faq-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
}

.faq-section h1 {
    font-size: 30px;
}

.faq-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.faq-question {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #ccc;
    padding: 10px;
    border-radius: 10px;
}

.faq-question-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.faq-question-container p {
    font-weight: 600;
}

.faq-question button {
    background-color: #b81104;
    color: #fff;
    border: none;
    outline: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.faq-answer-container {
    padding: 20px;
    margin-top: 10px;
    background-color: #b81104;
    color: #fff;
    border-radius: 10px;
}

.faq-answer-container a {
    color: #fff;
}