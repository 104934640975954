.our-gallery-section {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 30px;
    text-align: center;
    padding: 40px 20px;
}

.our-gallery-section>h1 {
    font-size: 30px;
    font-weight: 700;
    color: #b81104;
    text-transform: uppercase;
}

.our-gallery-section h4 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
}

.our-gallery-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.our-gallery-image img {
    height: 150px;
    width: 250px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s ease;
    object-fit: cover;
    object-position: center center;
}

.our-gallery-image img:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transform: scale(1.1);
}

.popup-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99;
}

.popup-image img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    z-index: 10;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

/* Responsive */
@media (max-width: 480px) {
    .our-gallery-image {
        width: 100%;
        height: 200px;
    }

    .our-gallery-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}