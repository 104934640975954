.virtual-tour-section,
.virtual-room-tour-section {
    width: 100%;
    height: fit-content;
    padding: 10px;
}

.virtual-room-tour-section h2 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
}

.virtual-tour-section iframe,
.virtual-room-tour-section iframe {
    border: none;
    border-radius: 10px;
}